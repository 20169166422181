import React from 'react';
import { StaticQuery, graphql } from 'gatsby'

const query = graphql`
    query {
        wordpressWpFooter {
            acf {
                companyName
                address
                email
                numberPhone
                zipCode
            }
        }
    }
`;
export default () => (
    <StaticQuery 
        query={query}
        render={data => (
            <>
                <h4>{ data.wordpressWpFooter.acf.companyName }</h4>
                <ul>
                    <li>{ data.wordpressWpFooter.acf.address }</li>
                    <li>{ data.wordpressWpFooter.acf.zipCode }</li>
                    <li>
                        <a href={'tel:'+ data.wordpressWpFooter.acf.numberPhone }>tel: { data.wordpressWpFooter.acf.numberPhone }</a>
                    </li>
                    <li>
                        <a href={'mailto:' + data.wordpressWpFooter.acf.email}>e-mail: { data.wordpressWpFooter.acf.email }</a>
                    </li>
                </ul>
            </>
        )}
    />
);