import React, { Component } from 'react'
import FooterInfo from './FooterInfo'
import FirstFooterMenu from './FirstFooterMenu'
import SecondFooterMenu from './SecondFooterMenu'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.svg'
import logo from '../../images/nord.svg'
import eu from '../../images/eu.png'

class Footer extends Component {
  render() {
    return (
      <footer>
        <div class="container">
          <div class="row">
            <div class="col">
              <div className="list">
                <h4>MS-MEBLE</h4>
                <ul>
                  <FirstFooterMenu />
                </ul>
              </div>
              <div className="list">
                <h4>Meble na wymiar</h4>
                <ul>
                  <SecondFooterMenu />
                </ul>
              </div>
              <div className="list">
                <h4>Obserwuj nas</h4>
                <ul>
                  <li className="socials">
                    <div>
                      <a
                        rel="noreferrer noopener"
                        href="https://www.facebook.com/msMeble/"
                        target="blank"
                      >
                        <img src={facebook} alt="MS Meble na Facebooku" />
                      </a>
                      <a
                        rel="noreferrer noopener"
                        href="https://www.instagram.com/msmeble/"
                        target="blank"
                      >
                        <img src={instagram} alt="MS Meble na Instagramie" />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3">
              <FooterInfo />
            </div>
          </div>
          <div class="row">
            <div class="col copy">
              <small>© 2018 MS-MEBLE</small>
              <small className="nord">
                Created by{' '}
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href="https://norddigital.com"
                >
                  {' '}
                  Norddigital <img src={logo} alt="Norddigital" />
                </a>
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img className="eu" src={eu} alt="Program Regionalny" />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
