import React, { useState } from 'react';
import pdf from './../../pdf/polityka_prywatnosci.pdf';

export default (props) => {
    let [ accepted, setAccepted ] = useState(false)
    const setCookie = () => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('cookie', 'Zaakceptowana polityka Cookie');
            setAccepted(true);
        }
    }
    return (
        <>
            { props.visible && !accepted && 
                <div className="cookie-info">
                    <p>
                        Do prawidłowego działania strony wykorzystujemy pliki cookies.
                        Dzięki temu możliwa jest poprawna analiza funkcjonalności strony oraz prowadzenie działań marketingowych. 
                        Działamy zgodnie z Polityką Prywatności. Korzystając dalej z naszej strony internetowej wyrażają Państwo zgodę na przedstawione zasady.
                        <a href={ pdf } target="_blank">Więcej</a>
                    </p>
                    <div className="button-wrap">
                        <button onClick={() => setCookie() }>Akceptuj</button>
                    </div>
                </div>
            }
        </>
    );
}