import React from 'react';
import PropTypes from 'prop-types';
// import PageTransition from 'gatsby-plugin-page-transitions';

import CookieInfo from "../components/cookie-info/CookieInfo";
import { StaticQuery, graphql } from 'gatsby';
import Footer from '../components/footer/Footer';
import Header from './header/Header';
import './../main.scss'

const checkCookie = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('cookie') != null) {
      return false;
    } else {
      return true;
    }
  }
}
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wordpressWpApiMenusMenusItems {
          items {
            title
            url
          }
        }
      }
    `}
    render={data => (
      <>
        
        <Header siteTitle={data.site.siteMetadata.title} />
          {/* <PageTransition> */}
            {children}
          {/* </PageTransition> */}

        <Footer />
        <CookieInfo visible={ checkCookie() } />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
