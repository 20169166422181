import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby';

class FirstFooterMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const query = graphql`
            query {
                wordpressWpApiMenusMenusItems(wordpress_id: {eq: 5}) {
                    items {
                      title
                      url
                      object_slug
                    }
                }
            }
        `;

        return (
            <StaticQuery 
                query={query}
                render={data => (
                    <>
                        { data.wordpressWpApiMenusMenusItems.items.map( (item) => 
                            <li key={item.object_slug}>
                                <Link to={( item.object_slug === 'home' ? '/' : item.object_slug) }>{item.title}</Link>
                            </li>
                        )}
                    </>
                )}
            />
        );
    }
}

export default FirstFooterMenu;