import { Link } from 'gatsby';
import React from 'react';
import MainMenu from './MainMenu';
import logo from './../../images/logo.svg'

export default () => (
    <header>
        <div className="container">
            <nav className="row">
                <h1 className="logo">
                    <Link to={'/'}>
                        <img src={ logo } alt="MS Meble" />
                    </Link>
                </h1>
                <MainMenu />        
            </nav>
        </div>
    </header>
);