import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby';
import facebook from '../../images/facebook.svg';
import instagram from '../../images/instagram.svg';


class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerToggled: false, 
            toggleHamburger: false
        }
    }
    render() {
        const query = graphql`
            query navQuery {
                wordpressWpApiMenusMenusItems {
                    items {
                        title
                        object_slug
                        # wordpress_children  {
                        #     title
                        #     object_slug
                        # }
                    }
                }
            }`;
        
        const Navigation = () => (
            <StaticQuery 
                query={query}
                render={data => (
                    <>
                        { data.wordpressWpApiMenusMenusItems.items.map( (item) => 
                            <li key={item.object_slug}>
                                <Link to={( item.object_slug === 'home' ? '/' : item.object_slug) }>{item.title}</Link>
                                { item.wordpress_children &&
                                    <ul className="dropdown">
                                        { item.wordpress_children.map( (el) => (
                                            <li key={ el.object_slug } >
                                                <Link to={( el.object_slug === 'home' ? '/' : el.object_slug) }>{el.title}</Link>
                                            </li>
                                        ))
                                        }
                                    </ul>
                                    
                                }
                            </li>
                        )}
                    </>
                )}
            />
        )

        return (
            <>
                <div className={ "hamburger " + (this.state.hamburgerToggled ?  'open' : '') } type="button" onClick={() => this.setState({ hamburgerToggled: !this.state.hamburgerToggled }) }>
                    <span role="icon" aria-label="hamburger"></span>
                    <span role="icon" aria-label="hamburger"></span>
                    <span role="icon" aria-label="hamburger"></span>
                    <span role="icon" aria-label="hamburger"></span>
                </div>
                <ul id="menu" className={'menu '  + (this.state.hamburgerToggled ? 'active' : ' close')}>
                    <Navigation />
                    <li className="socials">
                        <div>
                            <a rel="noreferrer noopener" href="https://www.facebook.com/msMeble/" target="blank">
                                <img src={ facebook } alt="MS Meble na Facebooku"/>
                            </a>
                            <a rel="noreferrer noopener" href="https://www.instagram.com/msmeble/" target="blank">
                                <img src={ instagram } alt="MS Meble na Instagramie"/>
                            </a>
                        </div>
                    </li>
                </ul>
            </>
        );
    }
}

export default MainMenu;